<template>
  <div class="tw-p-5 tw-w-screen">
    <div class="tw-container mx-auto">
      <b-card>
        <div class="text-center">
          <h1 class="tw-mb-2 tw-text-3xl tw-font-bold tw-text-gray-600">Api Documentation</h1>
          <p class="m-0 font-small-4 tw-text-gray-600">{{ $t('Project') }}: Test</p>
          <input
            class="tw-mt-6 tw-text-sm tw-bg-gray-50 tw-px-4 tw-py-2 tw-border tw-border-gray-200 tw-rounded-xl tw-placeholder-gray-400 tw-w-[500px] tw-outline-gray-800 tw-transition focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-gray-100"
            placeholder="Поиск"
            type="text"
          />
          <div class="tw-mt-6 tw-flex tw-flex-wrap tw-items-center tw-justify-center tw-gap-2 tw-font-medium">
            <span
              v-for="(tag, index) in tags"
              :key="tag.id"
              :class="selectedTag === index ? 'tw-border-sky-500 tw-bg-sky-50' : 'tw-border-gray-300'"
              class="tw-py-1 tw-px-3 tw-border tw-rounded-full tw-cursor-pointer tw-font-light"
              @click="onSelectTag(index)"
              >{{ tag.name }}</span
            >
          </div>
        </div>
        <div v-if="selectedTag !== null && selectedTag > 0" class="tw-flex tw-mt-10">
          <div class="tw-p-2 tw-border tw-border-gray-100 tw-rounded-lg tw-min-w-96 tw-space-y-1">
            <div
              v-for="(item, index) in data"
              :key="index"
              :class="{'tw-bg-gray-50 tw-border-gray-100': +$route.query.point === index}"
              class="tw-flex tw-mb-0 tw-items-center tw-text-sm tw-px-2 tw-py-1 tw-cursor-pointer tw-border tw-border-white hover:tw-bg-gray-50 hover:tw-border-gray-100 tw-rounded-md"
              @click="selectCategory(index)"
            >
              <span class="tw-truncate tw-mr-2">{{ item.title }}</span>
              <span
                :class="colorType(item.type)"
                class="tw-ml-auto tw-inline-flex tw-items-center tw-rounded-md tw-px-2 tw-py-1 tw-text-xs tw-font-medium tw-ring-1 tw-ring-inset"
                >{{ item.type }}
              </span>
            </div>
          </div>
          <div class="tw-flex-grow tw-ml-8 tw-flex">
            <template v-if="selectedCategory !== null && !reload">
              <div class="tw-flex-grow">
                <div class="tw-m-0 tw-flex tw-items-center">
                  <span
                    :class="colorType(selectedCategory.type)"
                    class="tw-inline-flex tw-items-center tw-rounded-md tw-px-2 tw-py-1 tw-text-xs tw-font-medium tw-ring-1 tw-ring-inset leading-normal"
                    >{{ selectedCategory.type }}
                  </span>
                  <span class="tw-ml-2 tw-text-sm">{{ selectedCategory.link }}</span>
                </div>
                <p class="tw-mt-2 tw-text-lg tw-font-bold tw-text-gray-600">{{ selectedCategory.title }}</p>
                <p>{{ selectedCategory.description }}</p>
              </div>
              <div class="lg:tw-min-w-[500px] tw-flex tw-flex-col tw-gap-y-4">
                <div
                  v-for="(point, index) in selectedCategory.points"
                  :key="index"
                  class="tw-overflow-hidden tw-rounded-xl tw-bg-zinc-800 tw-shadow-md dark:tw-ring-1 dark:tw-ring-white/10"
                >
                  <div class="tw-not-prose">
                    <div
                      class="tw-flex tw-flex-wrap tw-items-start tw-gap-x-4 tw-border-b tw-border-zinc-600 tw-bg-zinc-800 tw-px-4"
                    >
                      <h3 class="tw-mr-auto tw-pt-3 tw-text-xs tw-font-semibold tw-text-white">Request</h3>
                      <div
                        aria-orientation="horizontal"
                        class="-tw-mb-px tw-flex tw-gap-4 tw-text-xs tw-font-medium"
                        role="tablist"
                      >
                        <button
                          v-for="(block, index) in point.blocks"
                          id="headlessui-tabs-tab-:r1l:"
                          :key="index"
                          aria-controls="headlessui-tabs-panel-:r1p:"
                          aria-selected="true"
                          class="tw-border-b tw-py-3 tw-transition ui-not-focus-visible:tw-outline-none tw-border-emerald-500 tw-text-emerald-400"
                          data-headlessui-state="selected"
                          role="tab"
                          tabindex="0"
                          type="button"
                        >
                          {{ block.lang }}
                        </button>
                      </div>
                    </div>
                    <div>
                      <div
                        id="headlessui-tabs-panel-:r1p:"
                        aria-labelledby="headlessui-tabs-tab-:r1l:"
                        data-headlessui-state="selected"
                        role="tabpanel"
                        tabindex="0"
                      >
                        <div class="tw-group">
                          <div
                            class="tw-flex tw-h-9 tw-items-center tw-gap-2 tw-border-y tw-border-b-zinc-500 tw-border-t-transparent tw-bg-white/2.5 tw-bg-zinc-800 tw-px-4"
                          >
                            <div class="dark tw-flex tw-items-center">
                              <span
                                :class="colorType(selectedCategory.type, false)"
                                class="tw-font-mono tw-text-xs tw-font-semibold tw-leading-none"
                                >{{ selectedCategory.type }}
                              </span>
                            </div>
                            <span class="tw-h-1 tw-w-1 tw-rounded-full tw-bg-zinc-500"></span>
                            <span class="tw-font-mono tw-text-xs tw-text-zinc-400 tw-leading-none">{{
                              selectedCategory.link
                            }}</span>
                          </div>
                          <div class="tw-relative">
                            <vue-code-highlight :language="point.blocks[0].lang" class="one-dark-layout">
                              <pre>{{ point.blocks[0].code }}</pre>
                            </vue-code-highlight>
                            <button
                              class="group/button tw-absolute tw-right-4 tw-top-3.5 tw-overflow-hidden tw-rounded-full tw-py-1 tw-pl-2 tw-pr-3 tw-text-2xs tw-font-medium tw-opacity-0 tw-backdrop-blur tw-transition focus:tw-opacity-100 group-hover:tw-opacity-100 tw-bg-white/5 hover:tw-bg-white/7.5"
                              type="button"
                              @click="doCopy(point.blocks[0].code)"
                            >
                              <span
                                :class="{'tw-translate-y-1.5 tw-opacity-0': popupStatus}"
                                aria-hidden="false"
                                class="tw-pointer-events-none tw-flex tw-items-center tw-gap-0.5 tw-text-zinc-400 tw-transition tw-duration-300"
                                ><svg
                                  aria-hidden="true"
                                  class="tw-h-5 tw-w-5 tw-fill-zinc-500/20 tw-stroke-zinc-500 tw-transition-colors group-hover/button:tw-stroke-zinc-400"
                                  viewBox="0 0 20 20"
                                >
                                  <path
                                    d="M5.5 13.5v-5a2 2 0 0 1 2-2l.447-.894A2 2 0 0 1 9.737 4.5h.527a2 2 0 0 1 1.789 1.106l.447.894a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-5a2 2 0 0 1-2-2Z"
                                    stroke-width="0"
                                  ></path>
                                  <path
                                    d="M12.5 6.5a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-5a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2m5 0-.447-.894a2 2 0 0 0-1.79-1.106h-.527a2 2 0 0 0-1.789 1.106L7.5 6.5m5 0-1 1h-3l-1-1"
                                    fill="none"
                                    stroke-linejoin="round"
                                  ></path></svg
                                >Copy</span
                              ><span
                                :class="{'tw-translate-y-1.5 tw-opacity-0': !popupStatus}"
                                aria-hidden="true"
                                class="tw-pointer-events-none tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center tw-text-emerald-400 tw-transition tw-duration-300"
                                >Copied!</span
                              >
                            </button>
                          </div>
                        </div>
                      </div>
                      <span
                        id="headlessui-tabs-panel-:r1q:"
                        aria-labelledby="headlessui-tabs-tab-:r1m:"
                        role="tabpanel"
                        style="
                          position: fixed;
                          top: 1px;
                          left: 1px;
                          width: 1px;
                          height: 0px;
                          padding: 0px;
                          margin: -1px;
                          overflow: hidden;
                          clip: rect(0px, 0px, 0px, 0px);
                          white-space: nowrap;
                          border-width: 0px;
                        "
                        tabindex="-1"
                      ></span>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </b-card>
    </div>

    <div
      :class="{'tw-translate-x-0': popupStatus}"
      class="tw-fixed tw-bottom-[20px] tw-right-[20px] tw-bg-white tw-py-3 tw-px-4 tw-rounded-lg tw-shadow-lg tw-font-semibold tw-text-sky-600 tw-translate-x-56 tw-transition"
    >
      <p class="tw-m-0">{{ popupMessage }}</p>
    </div>
  </div>
</template>

<script>
import {BCard, VBToggle} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';

import {component as VueCodeHighlight} from 'vue-code-highlight';
import 'prismjs/themes/prism.css'; // you can change
import 'prism-es6/components/prism-markup-templating';
import 'prism-es6/components/prism-python';

export default {
  components: {
    VueCodeHighlight,
    BCard,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  data() {
    return {
      reload: false,
      searchQuery: '',
      tags: [
        {
          id: 0,
          name: 'Base',
        },
        {
          id: 1,
          name: 'Products',
        },
        {
          id: 2,
          name: 'Items',
        },
        {
          id: 3,
          name: 'Articles',
        },
        {
          id: 4,
          name: 'Users',
        },
      ],
      selectedTag: 0,
      selectedCategoryIndex: {
        id: null,
      },
      data: [
        {
          id: 0,
          title: 'Создание пользователя',
          type: 'POST',
          link: '/v1/users',
          description:
            'Создание пользователя в системе. При создании пользователя необходимо указать логин, пароль и email.',
          points: [
            {
              title: 'Создание пользователя',
              blocks: [
                {
                  text: 'venv:',
                  code: `{
  login: "user",
  password: "password",
  email: "email"
}`,
                  lang: 'json',
                },
              ],
            },
          ],
        },
        {
          id: 1,
          title: 'Обновление пользователя',
          type: 'PATCH',
          link: '/v1/users/:id',
          description: 'Обновление данных пользователя.',
          points: [
            {
              title: 'Обновление пользователя',
              blocks: [
                {
                  text: 'venv:',
                  code: `{
  login: "user",
  password: "password",
  email: "email"
}`,
                  lang: 'json',
                },
              ],
            },
          ],
        },
        {
          id: 2,
          title: 'Удаление пользователя',
          type: 'DELETE',
          link: '/v1/users/:id',
          description: 'Удаление пользователя из системы.',
          points: [
            {
              title: 'Удаление пользователя',
              blocks: [
                {
                  text: 'venv:',
                  code: `{
  login: "user",
  password: "password",
  email: "email"
}`,
                  lang: 'json',
                },
              ],
            },
          ],
        },
        {
          id: 3,
          title: 'Получение списка пользователей',
          type: 'GET',
          link: '/v1/users',
          description: 'Получение списка всех пользователей.',
          points: [
            {
              title: 'Получение списка пользователей',
              blocks: [
                {
                  text: 'venv:',
                  code: `{
  login: "user",
  email: "email"
}`,
                  lang: 'json',
                },
              ],
            },
          ],
        },
      ],
      popupStatus: false,
      popupMessage: 'Код скопирован!',
    };
  },
  mounted() {
    this.selectedTag = +this.$route.query.category;
  },
  computed: {
    query() {
      return this.$route.query;
    },
    selectedCategory() {
      return this.query.point ? this.data[this.query.point] : null;
    },
  },
  watch: {
    query() {
      this.selectedTag = +this.$route.query.category;
    },
  },
  methods: {
    colorType(type, tag = true) {
      switch (type) {
        case 'POST':
          return tag ? 'tw-text-purple-800 tw-bg-purple-50 tw-ring-purple-700/10' : 'tw-text-purple-400';
        case 'PATCH':
          return tag ? 'tw-text-yellow-800 tw-bg-yellow-50 tw-ring-yellow-600/20' : 'tw-text-yellow-500';
        case 'PUT':
          return tag ? 'tw-text-yellow-800 tw-bg-yellow-50 tw-ring-yellow-600/20' : 'tw-text-yellow-500';
        case 'DELETE':
          return tag ? 'tw-text-red-700 tw-bg-red-50 tw-ring-red-600/10' : 'tw-text-red-400';
        case 'GET':
          return tag ? 'tw-text-green-700 tw-bg-green-50 tw-ring-green-600/20' : 'tw-text-green-500';
        default:
          return tag ? 'tw-text-gray-600 tw-bg-gray-50 tw-ring-gray-500/10' : 'tw-text-gray-400';
      }
    },
    doCopy(text) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          this.popupStatus = true;
          setTimeout(() => {
            this.popupStatus = false;
          }, 3000);
        })
        .catch(err => {
          console.log('Something went wrong', err);
        });
    },
    async selectCategory(index) {
      this.reload = true;
      this.selectedCategoryIndex = await index;
      this.reload = false;
      await this.$router.push({query: {...this.$route.query, point: index}});
    },
    onSelectTag(id) {
      this.selectedTag = id;
      this.$router.push({query: {category: id}});
    },
  },
};
</script>

<style>
@import '~@/assets/scss/variables/prism-one-dark.css';
/* required class */
.my-editor {
  /* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
  color: #ccc;

  /* you must provide font-family font-size line-height. Example: */
  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 5px;

  pre {
    color: #fff;
  }
}

/* optional class for removing the outline */
.prism-editor__textarea:focus {
  outline: none;
}
</style>

<style lang="scss" scoped>
.container {
  padding: 30px 100px;
}

.dropdown {
  display: none;
}

/*code {*/
/*    background-color: #424c5a;*/
/*    color: #a37ad1;*/
/*    padding: 12px 20px;*/
/*    font-size: 14px;*/
/*    font-weight: bold;*/
/*    border-radius: 5px;*/
/*    display: inline-block;*/
/*    margin: 0;*/
/*    font-family: 'Roboto Mono', monospace;*/
/*}*/

.code_block {
  background-color: #424c5a;
  padding: 2px 6px;
  border-radius: 5px;
  font-size: 14px;
  cursor: text;
}

.mb {
  margin-bottom: 25px;
}

.block {
  margin-bottom: 30px;
  margin-left: 40px;
}

.btn-copy {
  background-color: #424c5a;
  padding: 3px 10px;
  margin-left: 7px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s linear;
  overflow: hidden;
  width: 12px;
  display: flex;
  align-items: center;
  color: #424c5a;
  height: 25px;
}

.btn-copy:hover {
  width: 132px;
  color: #707e8b;
}

.btn-copy i {
  color: #707e8b;
  font-size: 15px;
  margin-right: 10px;
}

.flex {
  display: flex;
}

.mt-1 {
  margin-top: 1rem;
}

.pt-15 {
  padding-top: 10px;
}

.container-block.active {
  height: auto;
}

.container-block > h2 {
  cursor: pointer;
}

/*.pln, .clo, .opn, .pun {*/
/*    color: #d4d4d4 !important;*/
/*}*/

/*.kwd, .typ {*/
/*    color: #a37ad1 !important;*/
/*}*/

/*.lit {*/
/*    color: #af8e46 !important;*/
/*}*/

/* Pretty printing styles. Used with prettify.js. */
/* Vim sunburst theme by David Leibovic */

.hljs {
  background-color: #424c5a94 !important;
  /* color: #a37ad1 !important; */
  padding: 12px 20px;
  font-size: 14px;
  /* font-weight: bold; */
  border-radius: 5px;
  display: inline-block;
  margin: 0;
  font-family: 'Roboto Mono', monospace;
}

.popup {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 15px 30px;
  background-color: #3a4450;
  border-radius: 8px;
  color: #4dab6f;
  transform: translateX(120%);
  transition: all 0.3s ease;
}

.popup.show {
  transform: translateX(0);
}

.popup p {
  margin: 0;
}

.card .card {
  border-radius: unset !important;
}

@media screen and (max-width: 768px) {
  body {
    padding: 30px 20px 0;
  }

  .container-block {
    height: 30px;
  }

  .container-block > h2 {
    font-size: 16px;
  }
}
</style>
